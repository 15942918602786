<template>
  <!-- <v-layout style="maxWidth: 1200px;"> -->
  <v-layout full-width>
    <v-app-bar fixed app color="blue-grey darken-3" dense dark>
      <v-btn icon to="/Dashboard">
        <v-icon large>mdi-arrow-left-thin-circle-outline</v-icon>
      </v-btn>
      <v-toolbar-title>{{ WebName }} {{ Roomname }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text color="pink accent-2" style="font-weight: bold;">Refresh<v-icon large right>mdi-refresh-circle</v-icon> </v-btn>
      <!-- เปิดทำงาน
      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title>เปิดแทง</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>ทดลองแทง</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>ปิดการทำงาน</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
    </v-app-bar>

    <v-row>
      <v-col cols="12" sm="6" md="6" lg="4">
        <v-card class="mx-auto my-2">
          <!-- <v-card-title class="white--text mt-8">
            <p class="ml-3">
              John Doe
            </p>
          </v-card-title> -->
          <v-card-text>
            <v-list two-line color="blue-grey darken-1" dark>
              <v-list-item-group active-class="pink--text">
                <ListRound />
                <v-divider></v-divider>
                <ListMachine />
                <v-divider></v-divider>
                <ListAccount />
                <v-divider></v-divider>
                <ListBet />
                <v-divider></v-divider>
                <ListBonus />
                <v-divider></v-divider>
                <ListMoney />
                <v-divider></v-divider>
                <ListSetTime />
                <v-divider></v-divider>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="8">
        <!-- ตาราง process
        {{ a }} -->
        <Table />
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

import ListRound from "@/components/List/Round";
import ListAccount from "@/components/List/Account";
import ListMachine from "@/components/List/Machine";
import ListBonus from "@/components/List/Bonus";
import ListBet from "@/components/List/Bet";
import ListMoney from "@/components/List/Money";
import ListSetTime from "@/components/List/SetTime";

import Table from "@/components/Manage/Table";

export default {
  name: "Manage",
  mixins: [globalFunctionMixin],

  components: {
    ListRound,
    ListAccount,
    ListMachine,
    ListBonus,
    ListBet,
    ListMoney,
    ListSetTime,
    Table,
  },
  mounted() {
    this.ckLogin();
    // this.$refs.b_nav.SetID(0);
    window.scrollTo(0, 0);
  },
  data: () => ({
    a: "",
    WebName: sessionStorage.getItem("WebName"),
    Roomname: sessionStorage.getItem("Roomname"),
  }),
  created() {
    this.fetchEventsList();
    this.timer = setInterval(this.fetchEventsList, 30000);
  },
  methods: {
    fetchEventsList() {
      this.a++;
      //   this.$http
      //     .get("events", (events) => {
      //       this.list = events;
      //     })
      //     .bind(this);
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
};
</script>

<style></style>
