<template>
  <v-list-item to="/machine_setting">
    <v-list-item-icon class="my-auto">
      <v-icon x-large color="white">
        mdi-laptop
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>จำนวน เครื่อง</v-list-item-title>
      <!-- <v-list-item-subtitle class="text--primary"
                >กำลังทำแทง</v-list-item-subtitle
              > -->
      <v-list-item-subtitle class="mt-2">1 เครื่อง</v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action>
      <v-list-item-action-text>รอยืนยัน</v-list-item-action-text>
      0 เครื่อง
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: "ListMachine",

  components: {
    // components,
  },

  //mounted: {},
  computed: {},
  methods: {},
  data: () => ({}),
};
</script>

<style></style>
