<template>
      <v-list-item to="/account_setting">
        <v-list-item-icon class="my-auto">
          <v-icon x-large color="white">
            mdi-account
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>จำนวน ID</v-list-item-title>
          <!-- <v-list-item-subtitle class="text--primary"
                >กำลังทำแทง</v-list-item-subtitle
              > -->
          <v-list-item-subtitle class="mt-2">36 ID</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-list-item-action-text>เปิดครั้งละ</v-list-item-action-text>
          6 ID
          <!-- <v-icon color="green">
            mdi-circle
          </v-icon> -->
        </v-list-item-action>
      </v-list-item>

</template>

<script>
export default {
  name: "ListAccount",

  components: {
    // components,
  },

  //mounted: {},
  computed: {},
  methods: {},
  data: () => ({}),
};
</script>

<style></style>
