<template>

      <v-list-item>
        <v-list-item-icon class="my-auto">
          <v-icon x-large color="white">
            mdi-chart-timeline-variant-shimmer
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>แทงแล้ว</v-list-item-title>
          <!-- <v-list-item-subtitle class="text--primary"
                >กำลังทำแทง</v-list-item-subtitle
              > -->
          <v-list-item-subtitle class="mt-2">1 จาก 32 รอบ</v-list-item-subtitle>
        </v-list-item-content>

        <!-- <v-list-item-action>
          <v-list-item-action-text>เปิดครั้งละ</v-list-item-action-text>
          6 ID
        </v-list-item-action> -->
      </v-list-item>

</template>

<script>
export default {
  name: "ListBet",

  components: {
    // components,
  },

  //mounted: {},
  computed: {},
  methods: {},
  data: () => ({}),
};
</script>

<style></style>
