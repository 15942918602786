<template>

      <v-list-item>
        <v-list-item-icon class="my-auto">
          <v-icon x-large color="white">
            mdi-currency-usd
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>เงินหมด/ใกล้หมด</v-list-item-title>
          <!-- <v-list-item-subtitle class="text--primary"
                >กำลังทำแทง</v-list-item-subtitle
              > -->
          <v-list-item-subtitle class="mt-2">0 / 0</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-icon color="gray">
            mdi-refresh
          </v-icon>
        </v-list-item-action>
      </v-list-item>

</template>

<script>
export default {
  name: "ListMoney",

  components: {
    // components,
  },

  //mounted: {},
  computed: {},
  methods: {},
  data: () => ({}),
};
</script>

<style></style>
