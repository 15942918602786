<template>
    <v-list-item  to="/round_setting">
      <v-list-item-icon class="my-auto">
        <v-icon x-large color="white">
          mdi-calendar-clock
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>งวดที่ 16</v-list-item-title>
        <!-- <v-list-item-subtitle class="text--primary"
                >กำลังทำแทง</v-list-item-subtitle
              > -->
        <v-list-item-subtitle class="mt-2">24 ธ.ค. 2564 เวลา 18.15 น.</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <v-list-item-action-text>กำลังแทง</v-list-item-action-text>
        <v-icon color="green">
          mdi-circle
        </v-icon>
      </v-list-item-action>
    </v-list-item>
</template>

<script>
export default {
  name: "ListRound",

  components: {
    // components,
  },

  //mounted: {},
  computed: {},
  methods: {},
  data: () => ({}),
};
</script>

<style></style>
